import React, { useContext, useState } from 'react'
import { LogoutOutlined, Search } from '@mui/icons-material'
import Dropdown from '../../../components/Dropdown'
import Touchable from '../../../components/Touchable'
import NavLink from './NavLink'
import { useAuth } from '../../../utils/auth'
import Typography from '../../../components/Typography'
import { useTranslation } from 'react-i18next'
import { useIsMobile } from '../../../utils/useWindowDimensions'
import { useNavigate } from 'react-router-dom'
import { colors, properties } from '../../../theme'
import AuthContext from '../../../contexts/AuthContext'
import styled from 'styled-components'
import Icon from '../../../components/Icon'
import zIndexes from '../../../utils/zIndexes'

const StyledNavRight = styled.div.attrs(({ className }) => ({
  className: ['nav-right', className].join(' '),
}))<{ isMobile: boolean }>(
  ({ isMobile }) => `
  flex: 1;
  justify-content: flex-end;
  display: flex;
  align-items: center;
  .nav-right-user {
    margin-left: ${isMobile ? '16px' : '20px'};
    margin-bottom: 5px;
  }
  .dropdown {
    top: -2.5px;
    .content {
      right: 0px;
      top: 32px;
      padding: 0px;
      z-index: ${zIndexes.navRightProfileDropdownContent};
      .item-container {
        margin: 0px;
      }
      .dropdown-profile-item {
        >div:first-child {
          display: flex;
          align-items: center;
          border-bottom: 1px solid ${colors.strokePrimary};
          padding: 12px 14px;
          .typography-nav-button1 {
            display: block;
            margin: 0 0 0 10.75px;
          }
        }
        >div:last-child {
          padding: 22px 16px;
          >.touchable {
            display: flex;
            align-items: center;
            .typography-nav-button1 {
              margin-left: 6px;
            }
          }
        }
      }
    }
  }
`
)

type Props = {
  className?: string
  onClickSearch: () => void
  displaySearch?: boolean
}

export default function NavRight({
  className,
  onClickSearch,
  displaySearch,
}: Props) {
  const isMobile = useIsMobile()
  const [profileDropdownIsExpanded, setProfileDropdownIsExpanded] =
    useState(false)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { auth, logout } = useAuth()
  const UserIcon = <Icon name={properties.layoutSettings.navRightUserIcon} />
  return (
    <StyledNavRight isMobile={isMobile} className={className}>
      {displaySearch !== false && (
        <Touchable className="nav-right-search" onClick={onClickSearch}>
          <Search />
        </Touchable>
      )}
      {!auth && (
        <NavLink
          className="nav-right-user"
          variant="nav-button1"
          onClick={() => navigate('/login')}
        >
          {UserIcon}
        </NavLink>
      )}
      {auth && (
        <Dropdown
          className="nav-right-user"
          withIcon={false}
          isExpanded={profileDropdownIsExpanded}
          onToggle={() => setProfileDropdownIsExpanded((p) => !p)}
          items={[
            <div key="123" className="dropdown-profile-item">
              <div>
                {UserIcon}
                <Typography variant="nav-button1">
                  {auth.me.first_name} {auth.me.last_name}
                </Typography>
              </div>
              <div>
                <Touchable
                  onClick={() => {
                    logout()
                    setProfileDropdownIsExpanded(false)
                    navigate('/login')
                  }}
                >
                  <LogoutOutlined />
                  <Typography variant="nav-button1">
                    {t('user.logOut')}
                  </Typography>
                </Touchable>
              </div>
            </div>,
          ]}
          label={UserIcon}
        />
      )}
    </StyledNavRight>
  )
}
