import { Item, MyItem } from '../api/items/types';

export function getItemSubPath({ age_group, gender_category }: Item) {
  return (
    age_group != 'Vuxen' ? 'kids'
    : gender_category == 'Dam' ? 'women'
    : gender_category == 'Herr' ? 'men'
    : 'items'
    );
}
