import React from 'react'
import { createUseStyles } from 'react-jss'
import NavLink from './NavLink'
import Touchable from '../../../components/Touchable'
import {
  LogoContainer,
  PartnerLogo,
  ReCRQLLogo,
} from '../../../components/Logo'
import { useTranslation } from 'react-i18next'
import { colors, properties, styles as themeStyles } from '../../../theme'
import SearchInput from './SearchInput'
import SearchDrawer from './SearchDrawer'
import { useNavigate } from 'react-router-dom'
import NavRight from './NavRight'
import ExternalLink from './ExternalLink'
import styled from 'styled-components'
import zIndexes from '../../../utils/zIndexes'
import { template } from '../../items/detail/utils'
import { useIsPathActive } from '../../../utils/useIsPathActive'

const navLayout = properties.layoutSettings.navDesktop
const navStyles = themeStyles.nav

const useStyles = createUseStyles({
  link: {
    padding: '0 25px',
  },
})

const MainContainer = styled.div`
  ${navStyles.desktopMainContainer}
`

const FlexContainer = styled.div<{ layout: typeof navLayout }>(
  ({ layout }) => `
  position: relative;
  display: flex;
  align-items: center;
  padding: 25px 80px;
  ${
    layout.bottomLine
      ? `
    border-bottom: 2px solid ${colors.strokePrimary};
  `
      : ''
  }
  z-index: ${zIndexes.navDesktopFlexContainer};
  background-color: ${colors.backgroundPrimary};
  ${navStyles.flexContainer};
`
)

const LinksContainer = styled.div<{ layout: typeof navLayout }>(
  ({ layout }) => `
  display: ${layout.logoTop ? 'inline-block' : 'flex'};
  ${
    layout.logoTop
      ? `
    margin-left: 50%;
    transform: translateX(-50%);
  `
      : ''
  }
  ${navStyles.linksContainer}
`
)
const LinksFlexContainer = styled.div<{ layout: typeof navLayout }>(
  ({ layout }) => `
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`
)

const NavLogoContainer = styled.div<{ layout: typeof navLayout }>(
  ({ layout }) => `
  flex: 1;
  background-color: white;
  ${
    layout.logoTop
      ? `
    display: flex;
    flex: 1;
    align-items: center;
    flex-direction: column;
  `
      : ''
  }
  ${navStyles.navLogo}
`
)

const StyledNavRight = styled(NavRight)<{ layout: typeof navLayout }>(
  ({ layout }) => `
  ${
    layout.logoTop
      ? `
    display: flex;
    align-items: center;
    position: absolute;
    right: 5%;
    float: right;
  `
      : ''
  }
`
)

function NavLogo() {
  const navigate = useNavigate()
  return (
    <NavLogoContainer className="nav-logo" layout={navLayout}>
      {template == 'outnorth' ? (
        <>
          <LogoContainer className="top-logo-container">
            <ReCRQLLogo
              onClick={() => navigate('/')}
              size="large"
              className="recrql touchable"
            />
            <PartnerLogo
              onClick={() => window.open(properties.storeLink.uri)}
              size="small"
              className="partner touchable"
            />
          </LogoContainer>
        </>
      ) : (
        <>
          <Touchable onClick={() => navigate('/')}>
            <LogoContainer className="top-logo-container">
              <PartnerLogo size="large" className="partner" />
              <ReCRQLLogo size="medium" className="recrql" />
            </LogoContainer>
          </Touchable>
        </>
      )}
    </NavLogoContainer>
  )
}

type Props = {
  searchOpen: boolean
  onClickSearch: () => void
}

export default function NavDesktop({ onClickSearch, searchOpen }: Props) {
  const { t } = useTranslation()
  const styles = useStyles()
  const navigate = useNavigate()
  if (template == 'outnorth') {
    return <OutnorthNav onClickSearch={onClickSearch} />
  }
  return (
    <MainContainer>
      {navLayout.logoTop && <NavLogo />}
      <FlexContainer layout={navLayout}>
        {!navLayout.logoTop && <NavLogo />}
        <LinksContainer layout={navLayout}>
          <LinksFlexContainer layout={navLayout}>
            {properties.navLinks
              .filter(({ navRender }) => navRender)
              .map(({ label, path }) => (
                <NavLinkWithPadding
                  key={label}
                  onClick={() => navigate(`/${path}`)}
                  path={path}
                >
                  {t(label)}
                </NavLinkWithPadding>
              ))}
            <ExternalLink
              className={styles.link}
              onClick={() => {
                window.open(properties.storeLink.uri)
              }}
              TypographyProps={{
                variant: 'nav-button1',
              }}
            >
              {properties.storeLink.title}
            </ExternalLink>
          </LinksFlexContainer>
        </LinksContainer>
        <StyledNavRight layout={navLayout} onClickSearch={onClickSearch} />
      </FlexContainer>
      <SearchDrawer
        belowNav={template == 'houdini' || template == 'kappahl'}
        open={searchOpen}
        onClose={onClickSearch}
        onSubmit={(query) => {
          navigate(`/search/${query}/`)
          onClickSearch()
        }}
      />
    </MainContainer>
  )
}

function NavLinkWithPadding({
  onClick,
  children,
  path,
}: {
  onClick: () => void
  children: React.ReactNode
  path: string
}) {
  const styles = useStyles()
  const active = useIsPathActive(`/${path}`)
  return (
    <NavLink
      onClick={onClick}
      className={styles.link}
      variant="nav-button1"
      active={active}
    >
      {children}
    </NavLink>
  )
}

function OutnorthNav({ onClickSearch }: Omit<Props, 'searchOpen'>) {
  const { t } = useTranslation()
  const styles = useStyles()
  const navigate = useNavigate()
  return (
    <MainContainer className="nav-desktop">
      <div className="logo-container">
        <div className="nav-logo-container">
          <NavLogo />
        </div>
        <SearchInput
          onSubmit={(query) => {
            navigate(`/search/${query}/`)
            onClickSearch()
          }}
        />
        <StyledNavRight
          displaySearch={false}
          layout={navLayout}
          onClickSearch={onClickSearch}
        />
      </div>
      <FlexContainer className="flex-container" layout={navLayout}>
        {properties.navLinks
          .filter(({ navRender }) => navRender)
          .map(({ label, path }) => (
            <NavLinkWithPadding
              key={label}
              path={path}
              onClick={() => navigate(`/${path}`)}
            >
              {t(label)}
            </NavLinkWithPadding>
          ))}
        <div className="external-link-container">
          <ExternalLink
            className={styles.link}
            onClick={() => {
              window.open(properties.storeLink.uri)
            }}
            TypographyProps={{
              variant: 'general-button1',
            }}
          >
            {properties.storeLink.title}
          </ExternalLink>
        </div>
      </FlexContainer>
    </MainContainer>
  )
}
